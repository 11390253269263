import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import Home11 from "../../assets/home_1_1.jpg";
import Home12 from "../../assets/home_1_2.jpg";
import Home3 from "../../assets/home_3.jpeg";
import Home41 from "../../assets/home_4_1.jpeg";
import Home42 from "../../assets/home_4_2.jpeg";
import Home43 from "../../assets/home_4_3.jpeg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import NewsCard from "../elements/NewsCard";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      currentNews: [],
      newsCounter: 0,
      error: false,
      success: false,
      sliderPaused: false,
    };
    this.newsSlider = this.newsSlider.bind(this);
    this.newsSliderManual = this.newsSliderManual.bind(this);
    this.form_mail = React.createRef();
    this.form_gdpr = React.createRef();
    this.slider = React.createRef();
  }

  componentDidMount() {
    this.fetchNews();
    this.timer = setInterval(
      () => this.newsSlider(),
      4000,
    );

    this.watchForSliderHoverIn();
    this.watchForSliderHoverOut();
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  watchForSliderHoverIn() {
    this.slider.current.addEventListener('mouseenter', () => {
      this.setState({
        sliderPaused: true,
      });
    });
  }

  watchForSliderHoverOut() {
    this.slider.current.addEventListener('mouseleave', () => {
      this.setState({
        sliderPaused: false,
      });
    });
  }

  sendMail = (e) => {
    if (
      !this.form_gdpr.current.checked ||
      !this.form_mail.current.value
    ) {
      this.setState({
        error: true,
      });
    } else {
      this.form_gdpr.current.checked = false;
      this.setState({
        error: false,
        success: true,
      });
      let html =
        "<h1>" +
        "Newsletter Registration<br><br>" +
        "</h1><p>E-Mail: " +
        this.form_mail.current.value +
        "</p>";
      fetch("https://hooks.zapier.com/hooks/catch/7306825/bpqwmit/", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ html }),
      });
      this.form_mail.current.value = "";
    }
  };

  fetchNews = async () => {
    let count = 1;
    let records = [];
    let records_filtered = []
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/appTEn3LDZ56nauxj/tblz9iP6YDZ3kq5kB`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
    }
    records.forEach((news, key) => {
      if (Object.keys(news.fields).length !== 0 && "Beschreibung_EN" in news.fields && "Bild" in news.fields && "Titel_EN" in news.fields) {
        records_filtered.push(news)
      }
    });
    this.setState({
      news: records_filtered,
      currentNews: [records_filtered[this.state.newsCounter]]
    });
  };

  newsSlider() {
    if (!this.state.sliderPaused) {
      let nC = this.state.newsCounter + 1;
      if (nC > this.state.news.length - 1) {
        nC = 0;
      }
      this.setState({
        currentNews: [this.state.news[nC]],
        newsCounter: nC,
      });
    }
  }

  newsSliderManual() {
    let nC = this.state.newsCounter + 1;
    if (nC > this.state.news.length - 1) {
      nC = 0;
    }
    this.setState({
      currentNews: [this.state.news[nC]],
      newsCounter: nC,
    });
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Homepage</title>
          <meta
            name="description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <meta property="og:title" content="Pancon - Homepage" />
          <meta
            property="og:description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/home"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavEN />
        <div className="hero home">
          <div className="red-bg-hero"></div>
          <div className="hero-content-home">
            <div className="heading1-cont news-h flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="news-header">Current News</h2>
            </div>
            <div ref={this.slider} className="news-container flex center-v center-h">
              {this.state.currentNews.map((news, i) => (
                <NewsCard key={i} lang="en" news={news} />
              ))}
              {this.state.currentNews.length > 0 ? <div className="news-slider-cont">
                <div onClick={this.newsSliderManual} className="slider-nav-backdrop">
                  <span className="news-nav-next">{">"}</span>
                </div>
              </div> : <div></div>}
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>Pancon</h1>
            </div>
            <div className="half">
              <p className="body">
                Pancon is an independent, medium-sized company in business since
                1968. Our customers come from various commercial and industrial
                industries with a strong focus on the automotive industry. We
                supply customers in North, Central and South America, Europe,
                and Asia. Thanks to our combined expertise in engineering and
                in-house production, we support our customers from design
                concept to full production. Our production facilities are
                located in Europe and Asia. This enables Pancon to be flexible
                in adapting to demand and changing customer requirements.
              </p>
            </div>
            <div className="half w-image">
              <img className="home-static" alt="A person assembling parts in a construction line" src={Home11}></img>
              <img className="home-relative" alt="Over shoulder view of a person doing technical drawings on paper" src={Home12}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v center-h">
              <h2>Registration Newsletter</h2>
            </div>
            <div className="two-thirds home flex center-h">
              <div className="newsletter-mail">
                <input
                  placeholder="E-Mail"
                  className="i-newsletter-mail"
                  ref={this.form_mail}
                ></input>
              </div>
              <label className="w-checkbox checkbox-cont flex row center-h">
                <input
                  ref={this.form_gdpr}
                  className="checkbox"
                  type="checkbox"
                ></input>
                <p className="checkbox-label">
                  I agree to this&nbsp;
                  <a
                    href="https://www.pancon.de/en/privacy-policy"
                    target={"_blank"}
                    className="accented"
                  >
                    Privacy Policy
                  </a>
                </p>
              </label>
              {this.state.error ? (
                <div className="full flex center-h">
                  <p className="body error-msg">
                    Please enter an email address and
                    agree to our privacy policy.
                  </p>
                </div>
              ) : null}
              {this.state.success ? (
                <div className="full flex center-h">
                  <p className="body success-msg">
                    Your registration was successful! Thank you!
                  </p>
                </div>
              ) : null}
              <div className="full flex center-h">
                <button onClick={this.sendMail} className="default">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote">
            "Pancon is a world-class, flexible partner for the complex
            requirements of our customers. We would be happy to share with you
            our many successful projects"<br></br>Klaus Leist - Managing
            Director and Partner
          </span>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>We have a new home</h2>
            </div>
            <div className="half">
              <p className="body">
                <strong>New address</strong>
                <br></br>
                Kaiser-Friedrich-Promenade 4<br></br>
                61348 Bad Homburg vor der Höhe<br></br>
                Germany<br></br>
                <br></br>
                <strong>New phone number</strong>
                <br></br>
                +49 6172 9180157{" "}
              </p>
            </div>
            <div className="half">
              <img className="img-full" src={Home3}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Our services</h2>
            </div>
            <div className="all-thirds-container flex">
              <div className="third-container third">
                <img className="img-full" src={Home41}></img>
                <h4>Engineering</h4>
                <p className="body">
                  Together with our customers, we develop specific products to
                  meet the customer requirements from design concept to full
                  production support, everything from a single source.<br></br>
                  Our IATF certification ensures the necessary level of quality.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={Home42}></img>
                <h4>Industry standard products</h4>
                <p className="body">
                  Pancon has a wide array of standardproducts which are
                  characterized by their high quality and short lead-times.Three
                  production sites in Europe and Asia ensure strong supply
                  capacity.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={Home43}></img>
                <h4>Custom products</h4>
                <p className="body">
                  Pancon’s capability of modifying standard products we also
                  have strong engineering capabilities to design and produce
                  complex assemblies to meet customers exact requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default Home;
