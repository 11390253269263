import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News1 from "../../assets/news_1.jpg";

class News_1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>
            News - Verfügbarkeitsengpass? Die Zeit ist reif für Pancon!
          </title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta
            property="og:title"
            content="News - Verfügbarkeitsengpass? Die Zeit ist reif für Pancon!"
          />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/verfugbarkeitsengpass-die-zeit-ist-reif-fur-pancon"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/verfugbarkeitsengpass-die-zeit-ist-reif-fur-pancon"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="section flex first intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1>Verfügbarkeitsengpass? Die Zeit ist reif für Pancon!</h1>
              <p className="body desc">29. März 2022</p>
              <img src={News1}></img>
              <div className="bottom-divider"></div>
              <p className="body">
                In der aktuellen Situation am Markt mit Lieferkettenproblemen
                und Engpässen in der Verfügbarkeit vieler Anbieter beweist
                PANCON, dass es auch anders geht.<br></br>Dank unserer auf höchster
                Lieferfähigkeit ausgelegten Fertigungsstrategie sind wir auch in
                Zeiten wie diesen in der Lage, sehr schnell und flexibel zu
                liefern.<br></br><br></br><strong>Beste Beispiele:‍</strong>Unsere CRIMP-CON und BOARD-CON
                Steckverbinder<br></br><br></br><strong>So profitieren Sie:</strong><br></br><strong>Kurze Lieferzeiten</strong><br></br>Bei
                uns in kürzester Zeit verfügbar – statt branchenüblichen
                Lieferzeiten von oftmals bis zu zwei Jahren.<br></br><strong>Bemusterungen für
                Testzwecke innerhalb von 3 Wochen</strong><br></br>Schneller testen, schneller
                entscheiden, mit PANCON besonders stark profitieren.<br></br><strong>Auch
                kundenspezifische Lösungen in großen Mengen schnell lieferbar</strong><br></br>
                Für kundenspezifische Projekte mit THR/SMD Steckverbindern
                können wir über 500.000 Einheiten noch in diesem Jahr liefern.
              </p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_1;
