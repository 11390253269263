import Hamburger from "hamburger-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/pancon_logo.png";
import "../../stylesheets/NavDE.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class NavDE extends Component {
  constructor(props) {
    super(props);
    this.state = { open: "closed" };
    this.searchInput = React.createRef();
    this.searchInputM = React.createRef();
    this.pNav = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.menuClick = this.menuClick.bind(this);
  }

  handleSubmit(event) {
    let searchValue = this.searchInput.current.value || this.searchInputM.current.value;
    if (searchValue) {
      window.open("https://www.pancon.de/de/produkte?search=" + searchValue);
    }
    event.preventDefault();
  }

  productMenu(e) {
    if (e.value === "Alle Produkte") {
      window.open("https://www.pancon.de/de/produkte", "_self");
    } else if (e.value === "Zubehör & Tools") {
      window.open("https://www.pancon.de/de/zubehoer-und-tools", "_self");
    } else if (e.value === "Produktübersicht") {
      window.open("https://www.pancon.de/de/produktuebersicht", "_self");
    } else {
      window.open("https://www.pancon.de/de/pcn", "_self");
    }
  }

  menuClick(e) {
    let currentState = this.state.open;
    if (currentState === "open") {
      this.setState({ open: "closed" });
    } else if (currentState === "closed") {
      this.setState({ open: "open" });
    }
  }

  render() {
    const options = [
      'Produktübersicht', 'Alle Produkte', 'Zubehör & Tools', 'Änderungsinformationen'
    ];
    return (
      <nav>
        <div className="section flex nav-upper">
          <div className="part-45 flex center-v left">
            <Link className="flex center-v left" to="/">
              <img className="nav-logo" alt="Das Logo von Pancon" src={logo}></img>
              <div>
                <span>PANCON</span>
                <br></br>
                <span>CONNECTORS</span>
              </div>
            </Link>
          </div>
          <div className="tenth flex center-v center-h">
            <span className="font-m active">DE</span>
            <span className="font-m m-left-1">|</span>
            <Link to="/en/home">
              <span className="font-m m-left-1">EN</span>
            </Link>
          </div>
          <div className="part-45 search-container flex center-v right">
            <div className="hamburger-cont">
              <Hamburger onToggle={this.menuClick} />
            </div>
            <form onSubmit={this.handleSubmit}>
              <input
                ref={this.searchInput}
                type="text"
                placeholder="Suchen..."
                value={this.state.value}
                className="search-input"
              />
              <input
                onClick={this.handleSubmit}
                className="search-submit"
                type="submit"
                value="Suchen"
              />
            </form>
          </div>
        </div>
        <div className="nav-lower flex spb center-v">
          <Dropdown id="p-dropdown" ref={this.pNav} options={options} onChange={this.productMenu} value={"Produkte"} placeholder="Produkte" />
          <span className="accent">/</span>
          <Link to="/de/automotive-solutions">
            <span>Automotive-/Industrial-Solutions</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/en/lumex">
            <span>LUMEX</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/de/distribution">
            <span>Distribution</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/de/referenzen">
            <span>Referenzen</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/de/ueber-uns">
            <span>Über Uns</span>
          </Link>
          <span className="accent">/</span>
          <Link to="/de/kontakt">
            <span>Kontakt</span>
          </Link>
        </div>
        <div className={"mob-menu " + this.state.open}>
          <div className="flex center-v center-h">
            <span className="font-m active">DE</span>
            <span className="font-m m-left-1">|</span>
            <Link to="/en/home">
              <span className="font-m m-left-1">EN</span>
            </Link>
          </div>
          <form className="flex center-h" onSubmit={this.handleSubmit}>
            <input
              ref={this.searchInputM}
              onClick={this.handleSubmit}
              type="text"
              placeholder="Suchen..."
              className="search-input"
            />
            <input className="search-submit" type="submit" value="Search" />
          </form>
          <div className="mob-nav-links flex col">
            <Link className="nav-link" to="/de/produkte">
              <span>Alle Produkte</span>
            </Link>
            <Link className="nav-link" to="/de/produktuebersicht">
              <span>Produktübersicht</span>
            </Link>
            <Link className="nav-link" to="/de/pcn">
              <span>Änderungsinformationen</span>
            </Link>
            <Link className="nav-link" to="/de/automotive-solutions">
              <span>Automotive-/Industrial-Solutions</span>
            </Link>
            <Link className="nav-link" to="/en/lumex">
              <span>LUMEX</span>
            </Link>
            <Link className="nav-link" to="/de/distribution">
              <span>Distribution</span>
            </Link>
            <Link className="nav-link" to="/de/referenzen">
              <span>Referenzen</span>
            </Link>
            <Link className="nav-link" to="/de/ueber-uns">
              <span>Über Uns</span>
            </Link>
            <Link className="nav-link" to="/de/kontakt">
              <span>Kontakt</span>
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavDE;
