import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import { Link } from "react-router-dom";
import FooterDE from "../Symbols/FooterDE";
import About1 from "../../assets/about_1.jpg";
import AS1 from "../../assets/as_1.jpg";
import AS2 from "../../assets/as_2.jpg";
import AS3 from "../../assets/as_3.jpg";
import AS4 from "../../assets/as_4.jpg";
import AS5 from "../../assets/as_5.jpg";
import AS6 from "../../assets/as_6.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/AboutUs.css";

class AutomotiveSolutions extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Automotive Solutions</title>
          <meta
            name="description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <meta property="og:title" content="Pancon - Automotive Solutions" />
          <meta
            property="og:description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/automotive-solutions"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/automotive-solutions"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero auto-sol">
          <div className="red-bg-hero"></div>
          <div className="hero-content">
            <h1 className="hero-heading">Automotive Solutions</h1>
            <p className="hero-desc">
              Kompetenz in Entwicklung und Fertigung von hochkomplexen
              Steckverbindungen für Automotive {"&"} Industrie
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Mehr erfahren
            </button>
          </div>
        </div>
        <div
          ref={this.intro}
          id="intro"
          className="section first flex center-h"
        >
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <h2>
                Umfangreiche Engineering-Kompetenzen im Bereich Produkt- und
                Prozessentwicklung
              </h2>
            </div>
            <div className="half">
              <p className="body">
                Wir verfügen über langjährige Kompetenzen in den Bereichen:
                <ul>
                  <li>
                    Moulding and Overmoulding of connectors and ECU applications
                  </li>
                  <li>Assembly of connector applications</li>
                  <li>Customer specific solutions</li>
                  <li>Pressfit solutions</li>
                  <li>THR/SMD connectors</li>
                  <li>IDC applications</li>
                </ul>
              </p>
            </div>
            <div className="half w-image">
              <img src={About1}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Lösungen</h2>
            </div>
            <p className="body">
              Eine Stärke von Pancon ist das Entwickeln von kundenspezifischen
              Lösungen. Gemeinschaftlich mit den Kunden wird am Produkt
              gearbeitet. Pancon bietet nicht nur abgewandelte Standards an,
              sondern geht auch auf komplexe Kundenanforderungen ein.
            </p>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">IDC Anwendungen</h3>
                <p className="body as">
                  Insulation Displacement Connection (IDC), ist eine einfache
                  und sichere Verbindung zwischen Kabel und Komponente. Das
                  Kabel wird in den Schneidklemmverbinder eingepresst, der die
                  Isolierung durchtrennt, um die Verbindung herzustellen.
                  Verfügbar für viele AWG-Serien Ein- und zweireihige
                  Anwendungen Einfache Montage Zusätzliche
                  Befestigungsmöglichkeiten
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS1}></img>
              </div>
            </div>
            <div className="flex as-top first">
              <div className="two-thirds">
                <h3 className="as">THR/SMD Steckverbinder</h3>
                <p className="body as">
                  Through Hole Reflow - Löten, Löten von Bauteilen in
                  Leiterplattenlöcher oder SMD zum Auflöten auf die
                  Leiterplatte. Der Einsatz fortschrittlicher
                  Kunststoffmaterialien macht die Verarbeitung im Heißluftofen
                  möglich. Dies ist ein schnelles und sicheres Verfahren auch
                  bei hohen Kontaktzahlen.
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS2}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">Pressfit / Needle eye - Lösungen</h3>
                <p className="body as">
                  <ul>
                    <li>
                      Einpressverbindungen zur Kontaktierung in TH - PCB's
                    </li>
                    <li>0,6mm und 0,8mm Pressfit Zone verfügbar</li>
                    <li>
                      Geeignet für verschiedene Leiterplatten und
                      Lochdurchmesser
                    </li>
                    <li>Andere Größen auf Anfrage</li>
                  </ul>
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS3}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Steckverbinder-Montageprozesse zur Erfüllung der
                  Kundenanforderungen einschließlich hochautomatisierter
                  Fertigungsanlagen
                </h3>
                <p className="body as">
                  Den Umfang der Automation in unserer Fertigung passen wir
                  stets an die Anforderungen der Projekte an.
                </p>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS4}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Spritzen und Umspritzen von Steckverbindern und elektronischen
                  Steuereinheiten
                </h3>
                <p className="body as">
                  Diese Technologie ermöglicht es Pancon, einfache und komplexe
                  Baugruppendesigns mit hochpräzisen Spritzgusslösungen
                  einschließlich IDC- und Pressfit-Kontakten innerhalb der
                  Baugruppe zu realisieren. Vollautomatische Verarbeitung für
                  einzigartige Anwendungen:
                </p>
                <ul>
                  <li>
                    Kontakteinheit für die hintere Differentialwelle der Ford
                    C2-Plattform
                  </li>
                  <li>Automotive Motorgehäuse für Start-Stopp-Ölpumpe</li>
                </ul>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS5}></img>
              </div>
            </div>
            <div className="flex as-top">
              <div className="two-thirds">
                <h3 className="as">
                  Spritzen und Umspritzen von Steckverbindern und
                  Steuergeräteanwendungen (halbautomatisch)
                </h3>
                <p className="body as">
                  Neben der vollautomatischen Produktion bietet Pancon auch
                  Möglichkeiten der teilautomatisierten Produktion und der
                  manuellen Produktion. Anwendungen:
                </p>
                <ul>
                  <li>Ladezustandsanzeigen für Elektrofahrzeuge</li>
                  <li>Bedienelemente</li>
                </ul>
              </div>
              <div className="third flex center-h center-v">
                <img src={AS6}></img>
              </div>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h">
              <p className="eye-catcher">Interessiert?</p>
            </div>
            <div className="half flex center-v center-h col">
              <Link className="default" to="/de/kontakt">
                <span>Kontaktieren Sie uns jetzt</span>
              </Link>
              <p className="body">Wir freuen uns auf Ihre Anfrage!</p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default AutomotiveSolutions;
