import React, { Component } from "react";
import "../../stylesheets/Produkte.css";
import MetaTags from "react-meta-tags";
import PCNCard from "../elements/PCNCard";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";

class PCN_EN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      pcn: [],
      offset: "",
    };
  }

  componentDidMount() {
    this.fetchPCN();
  }

  fetchPCN = async () => {
    let offset = "";
    let view = "viwNtmHBZiyXzoMoN";
    let count = 10;
    let records = [];
    let records_filtered = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/tblYw2v1clJpgtyd8?offset=${offset}&view=${view}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }

    records.forEach((pcn, key) => {
      if (Object.keys(pcn.fields).length !== 0 && "Datum" in pcn.fields && "Produktkategorie" in pcn.fields && "Beschreibung_DE" in pcn.fields && "Beschreibung_EN" in pcn.fields && "Published" in pcn.fields && "PCN_PDF" in pcn.fields) {
        records_filtered.push(pcn)
      }
    });

    this.setState({
      pcn: records_filtered,
    });
  };

  changeView = (e, target) => {
    this.setState({
      active: target,
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - PCN</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - PCN" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/pcn"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/pcn"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavEN />
        <div className="section first">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>Product Change Notifications</h1>
          </div>
          {/*           <div className="flex center-h">
            <button
              className={
                this.state.active === "MAS-CON"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "MAS-CON")}
            >
              MAS-CON
            </button>
            <button
              className={
                this.state.active === "CRIMP-CON" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "CRIMP-CON")}
            >
              CRIMP-CON
            </button>
            <button
              className={
                this.state.active === "BOARD-CON" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "BOARD-CON")}
            >
              BOARD-CON
            </button>
            <button
              className={
                this.state.active === "HI-CON" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "HI-CON")}
            >
              HI-CON
            </button>
            <button
              className={
                this.state.active === "LAT-CON" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "LAT-CON")}
            >
              LAT-CON
            </button>
          </div> */}
        </div>
        <div>
          <div>
            <div className="section flex" id="zubehoer-tools">
              {this.state.pcn.map((pcn, i) => (
                <PCNCard key={i} pcn={pcn} lang={"en"} />
              ))}
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default PCN_EN;
