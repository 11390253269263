import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import Home11 from "../../assets/home_1_1.jpg";
import Home12 from "../../assets/home_1_2.jpg";
import Home3 from "../../assets/home_3.jpeg";
import Home41 from "../../assets/home_4_1.jpeg";
import Home42 from "../../assets/home_4_2.jpeg";
import Home43 from "../../assets/home_4_3.jpeg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NewsCard from "../elements/NewsCard";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      currentNews: [],
      newsCounter: 0,
      error: false,
      success: false,
      sliderPaused: false,
    };
    this.newsSlider = this.newsSlider.bind(this);
    this.newsSliderManual = this.newsSliderManual.bind(this);
    this.form_mail = React.createRef();
    this.form_gdpr = React.createRef();
    this.slider = React.createRef();
  }

  componentDidMount() {
    this.fetchNews();
    this.timer = setInterval(
      () => this.newsSlider(),
      4000,
    );

    this.watchForSliderHoverIn();
    this.watchForSliderHoverOut();
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  watchForSliderHoverIn() {
    this.slider.current.addEventListener('mouseenter', () => {
      this.setState({
        sliderPaused: true,
      });
    });
  }

  watchForSliderHoverOut() {
    this.slider.current.addEventListener('mouseleave', () => {
      this.setState({
        sliderPaused: false,
      });
    });
  }

  sendMail = (e) => {
    if (
      !this.form_gdpr.current.checked ||
      !this.form_mail.current.value
    ) {
      this.setState({
        error: true,
      });
    } else {
      this.form_gdpr.current.checked = false;
      this.setState({
        error: false,
        success: true,
      });
      let html =
        "<h1>" +
        "Newsletter Registration<br><br>" +
        "</h1><p>E-Mail: " +
        this.form_mail.current.value +
        "</p>";
      fetch("https://hooks.zapier.com/hooks/catch/7306825/bpqwmit/", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ html }),
      });
      this.form_mail.current.value = "";
    }
  };

  fetchNews = async () => {
    let count = 1;
    let records = [];
    let records_filtered = []
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/appTEn3LDZ56nauxj/tblz9iP6YDZ3kq5kB`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
    }
    records.forEach((news, key) => {
      if (Object.keys(news.fields).length !== 0 && "Beschreibung_DE" in news.fields && "Bild" in news.fields && "Titel" in news.fields && "Published" in news.fields) {
        records_filtered.push(news)
      }
    });
    this.setState({
      news: records_filtered,
      currentNews: [records_filtered[this.state.newsCounter]]
    });
  };

  newsSlider() {
    if (!this.state.sliderPaused) {
      let nC = this.state.newsCounter + 1;
      if (nC > this.state.news.length - 1) {
        nC = 0;
      }
      this.setState({
        currentNews: [this.state.news[nC]],
        newsCounter: nC,
      });
    }
  }

  newsSliderManual() {
    let nC = this.state.newsCounter + 1;
    if (nC > this.state.news.length - 1) {
      nC = 0;
    }
    this.setState({
      currentNews: [this.state.news[nC]],
      newsCounter: nC,
    });
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Homepage</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - Homepage" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/home"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero home">
          <div className="red-bg-hero"></div>
          <div className="hero-content-home">
            <div className="heading1-cont news-h flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2 className="news-header">Aktuelle News</h2>
            </div>
            <div ref={this.slider} className="news-container flex center-v center-h">
              {this.state.currentNews.map((news, i) => (
                <NewsCard key={i} lang="de" news={news} />
              ))}
              {this.state.currentNews.length > 0 ? <div className="news-slider-cont">
                <div onClick={this.newsSliderManual} className="slider-nav-backdrop">
                  <span className="news-nav-next">{">"}</span>
                </div>
              </div> : <div></div>}
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>Pancon</h1>
            </div>
            <div className="half">
              <p className="body">
                Pancon ist ein unabhängiges, mittelständiges Unternehmen und
                existiert seit 1968.<br></br>Unsere Kunden kommen aus
                verschiedenen Industrien. Ein Schwerpunkt ist die
                Automotive-Branche. Wir beliefern Kunden in Europa, Amerika und
                Asien.<br></br>Durch unsere kombinierte Kompetenz aus
                Engineering und eigener Fertigung begleiten wir unsere Kunden
                von den ersten Design-Überlegungen bis zur Serienfertigung.
                <br></br>Unsere Fertigungsstätten befinden sich in Europa und
                Asien. Damit können wir unsere Kapazitäten an die wechselnden
                Bedarfe flexibel anpassen.
              </p>
            </div>
            <div className="w-image half">
              <img className="home-static" alt="A person assembling parts in a construction line" src={Home11}></img>^
              <img className="home-relative" src={Home12}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v center-h">
              <h2>Anmeldung Newsletter</h2>
            </div>
            <div className="two-thirds home flex center-h">
              <div className="newsletter-mail">
                <input
                  placeholder="E-Mail"
                  className="i-newsletter-mail"
                  ref={this.form_mail}
                ></input>
              </div>
              <label className="w-checkbox checkbox-cont flex row center-h">
                <input
                  ref={this.form_gdpr}
                  className="checkbox"
                  type="checkbox"
                ></input>
                <p className="checkbox-label">
                  Ich stimme der Verarbeitung meiner Daten basierend auf
                  dieser&nbsp;
                  <a
                    href="https://www.pancon.de/de/datenschutzerklaerung"
                    target={"_blank"}
                    className="accented"
                  >
                    Datenschutzerklärung
                  </a>
                  &nbsp; zu.
                </p>
              </label>
              {this.state.error ? (
                <div className="full flex center-h">
                  <p className="body error-msg">
                    Bitte füllen Sie das E-Mail-Feld aus
                    und stimmen der Datenschutzerklärung zu.
                  </p>
                </div>
              ) : null}
              {this.state.success ? (
                <div className="full flex center-h">
                  <p className="body success-msg">
                    Deine Anmeldung war erfolgreich! Vielen Dank!
                  </p>
                </div>
              ) : null}
              <div className="full flex center-h">
                <button onClick={this.sendMail} className="default">
                  Senden
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section full home flex center-h center-v">
          <span className="bg-quote">
            "Pancon ist ein flexibler Partner für die komplexen Anforderungen
            unserer Kunden. Wir können von vielen erfolgreichen Projekte
            berichten."<br></br>Klaus Leist - Managing Director and Partner
          </span>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Anschrift</h2>
            </div>
            <div className="half">
              <p className="body">
                <strong>Adresse</strong>
                <br></br>
                Kaiser-Friedrich-Promenade 4<br></br>
                61348 Bad Homburg vor der Höhe<br></br>
                Deutschland<br></br>
                <br></br>
                <strong>Rufnummer</strong>
                <br></br>
                +49 6172 9180157{" "}
              </p>
            </div>
            <div className="half">
              <img className="img-full" src={Home3}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h2>Unsere Leistungen</h2>
            </div>
            <div className="all-thirds-container flex">
              <div className="third-container third">
                <img className="img-full" src={Home41}></img>
                <h4>Engineering</h4>
                <p className="body">
                  Gemeinsam mit unseren Kunden entwickeln wir spezifische
                  Produkte.<br></br>Von den ersten Ideen bis zur Serienfertigung
                  kommt alles aus einer Hand.<br></br>Unsere IATF Zertifizierung
                  sorgt für den notwendigen Level an Qualität.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={Home42}></img>
                <h4>Industrie-Standard-Produkte</h4>
                <p className="body">
                  Unsere Standard-Produkte zeichnen sich aus durch eine hohe
                  Qualität und kurze Lieferzeiten.<br></br>Drei
                  Produktionsstandorte in Europa und Asien sorgen für die
                  notwendige Kapazität.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={Home43}></img>
                <h4>Kundenspezifische Produkte</h4>
                <p className="body">
                  Wir modifizieren nicht nur unsere Standardprodukte, sondern
                  wir realisieren ebenfalls komplexe Baugruppen gemäß
                  Kundenanforderungen.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Home;
