import React, { Component } from "react";
import logo from "../../assets/pancon_logo_large.png";

class PCNCard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      this.props.lang === "de" ?
        <a className="card pcn" href={this.props.pcn.fields.PCN_PDF[0].url} target="_blank">
          <div className="card-left pcn part-30 flex col center-v center-h">
            <img src={logo} width="32" height="32"></img>
            <span className="card-title">{this.props.pcn.fields.Datum}</span>
            <span className="card-title">PCN-Nummer: {this.props.pcn.fields.PCN_Nummer}</span>
          </div>
          <div className="card-divider"></div>
          <div className="card-right flex part-70">
            <div className="card-desc flex center-h col">
              <span className="prod-attr">{this.props.pcn.fields.Produktkategorie}</span>
              <span className="prod-attr-desc">Produktreihe</span>
            </div>
            <div className="card-desc pcn-desc flex center-h col">
              <span className="prod-attr">Beschreibung</span>
              <span className="prod-attr-desc">{this.props.pcn.fields.Beschreibung_DE}</span>
            </div>
          </div>
        </a>
        : <a className="card pcn" href={this.props.pcn.fields.PCN_PDF[0].url} target="_blank">
          <div className="card-left pcn part-30 flex col center-v center-h">
            <img src={logo} width="32" height="32"></img>
            <span className="card-title">{this.props.pcn.fields.Datum}</span>
            <span className="card-title">PCN-Number: {this.props.pcn.fields.PCN_Nummer}</span>
          </div>
          <div className="card-divider"></div>
          <div className="card-right flex part-70">
            <div className="card-desc flex center-h col">
              <span className="prod-attr">{this.props.pcn.fields.Produktkategorie}</span>
              <span className="prod-attr-desc">Series</span>
            </div>
            <div className="card-desc pcn-desc flex center-h col">
              <span className="prod-attr">Description</span>
              <span className="prod-attr-desc">{this.props.pcn.fields.Beschreibung_EN}</span>
            </div>
          </div>
        </a>
    );
  }
}

export default PCNCard;
