import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class Impressum extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Impressum</title>
          <meta
            name="description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <meta property="og:title" content="Pancon - Impressum" />
          <meta
            property="og:description"
            content="Pancon mit Sitz in Bad Homburg, produziert und vertreibt elektromechanische Komponenten seit 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/impressum"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/imprint"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="section flex first intro center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>Impressum</h1>
            </div>
            <div className="flex col">
              <h2>Angaben gemäß § 5 TMG</h2>
              <h4>Pancon GmbH</h4>
              <p className="body">
                Gesellschaft für elektromechanische Bauelemente<br></br>
                Kaiser-Friedrich-Promenade 4<br></br>D-61348 Bad Homburg v. d.
                Höhe<br></br>Deutschland / Germany<br></br>Tel.: +49 6172
                9180157
                <br></br>E-Mail: info@pancon.de<br></br>
                <br></br>Geschäftsführer / General Manager: Klaus Leist<br></br>
                Amtsgericht Bad Homburg / Commercial Register Bad Homburg: HRB
                12252<br></br>Ust.-Ident-Nr. /&nbsp;VAT Id.-No.: DE 283 028 695
              </p>
              <h2>
                <strong>Kontakt</strong>
              </h2>
              <p className="body">
                <a href="mailto:info@pancon.de">info@pancon.de</a>
              </p>
              <h2>
                <strong>
                  Hinweis auf EU-Streitschlichtung<br></br>
                </strong>
              </h2>
              <p className="body">
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{" "}
                <a href="http://ec.europa.eu/consumers/odr" target="_blank">
                  http://ec.europa.eu/consumers/odr
                </a>
                . Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <h2>
                <strong>
                  Haftung für Inhalte<br></br>
                </strong>
              </h2>
              <p className="body">
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen.
              </p>
              <h2>
                <strong>
                  Haftung für Links<br></br>
                </strong>
              </h2>
              <p className="body">
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
                <br></br>
              </p>
              <h2>
                <strong>Urheberrecht</strong>
              </h2>
              <p className="body">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
              </p>{" "}
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Impressum;
