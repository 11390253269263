import React, { Component } from "react";
import logo from "../../assets/pancon_logo_large.png";
import { Link } from "react-router-dom";

class DistributorEl extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dist-cont">
        <h3>{this.props.dist.fields.Name}</h3>
        <div className="flex row cont-fourths">
          <div className="flex center-v quart">
            <img src={this.props.dist.fields.Logo[0].url}></img>
          </div>
          <div className="flex center-v quart">
            <span dangerouslySetInnerHTML={{ __html: this.props.dist.fields.Addresse }}></span>
          </div>
          <div className="flex center-v quart">
          <span dangerouslySetInnerHTML={{ __html: this.props.dist.fields.Telefon_Fax }}></span>
          </div>
          <div className="flex center-v quart">
            <a target={"_blank"} href={this.props.dist.fields.Distributor_Link} className="default">Website</a>
          </div>
        </div>
      </div>
    );
  }
}

export default DistributorEl;
