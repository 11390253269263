import React, { Component } from "react";
import Logo from "../../assets/pancon_logo_large.png";

class NewsArchiveCard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    return (
      this.props.lang === "de" ?
        <a
          className="news-cont flex col center-v center-h"
          href={this.props.news.fields.Link_DE}
          target="_blank"
        >
          <img src={Logo}></img>
          <h4 className="news-archive-header">{this.props.news.fields.Titel_DE}</h4>
          <h5 className="news-archive-subheader">{this.props.news.fields.Datum}</h5>
        </a>
        :
        <a
          className="news-cont flex col center-v center-h"
          href={this.props.news.fields.Link_EN}
          target="_blank"
        >
          <img src={Logo}></img>
          <h4 className="news-archive-header">{this.props.news.fields.Title_EN}</h4>
          <h5 className="news-archive-subheader">{this.props.news.fields.Datum}</h5>
        </a>
    );
  }
}

export default NewsArchiveCard;
