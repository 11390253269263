import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";

class DatenschutzerklaerungEN extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Privacy Policy</title>
          <meta
            name="description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <meta property="og:title" content="Pancon - Privacy Policy" />
          <meta
            property="og:description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/datenschutzerklaerung"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/privacy-policy"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavEN />
        <div className="section flex first intro center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <div className="heading-deco m-right-3"></div>
              <h1>Privacy Policy</h1>
            </div>
            <div className="flex col">
              <p className="body">
                <strong>
                  1. Geltungsbereich<br></br>
                </strong>
                Diese Datenschutzerklärung gilt für das Internet-Angebot der
                Pancon GmbH Gesellschaft für elektromechanische Bauelemente,
                <br></br>eingetragen im Handelsregister B des Amtsgerichts Bad
                Homburg unter der Nummer 12252, Kaiser-Friedrich-Promenade 4,
                61348 Bad Homburg v. d. Höhe, Deutschland welches auf den unter
                der Domain{" "}
                <a href="http://www.pancon.de/">http://www.pancon.de</a>{" "}
                abrufbaren Websites abrufbar ist.
              </p>
              <p className="body">
                &zwj;<br></br>
                <strong>
                  2. Information über die Erhebung personenbezogener Daten
                  <br></br>
                </strong>
                Personenbezogene Daten sind Informationen, die dazu genutzt
                werden können, persönliche oder sachliche Verhältnisse über Sie
                zu erfahren (z.B. Name, Anschrift, Telefonnummer, Geburtsdatum
                oder E-Mail-Adresse). Informationen, bei denen wir keinen (oder
                nur mit einem unverhältnismäßigen Aufwand) Bezug zu Ihrer Person
                herstellen können, z.B. durch Anonymisierung der Informationen,
                sind keine personenbezogenen Daten.<br></br>
                <br></br>Verantwortlicher gem. Art. 4 Abs. 7
                EU-Datenschutz-Grundverordnung (DS-GVO) ist die Pancon GmbH
                Gesellschaft für elektromechanische Bauelemente. Telefonisch
                sind wir unter der Nummer +49 (0) 1511 6740037 erreichbar.
              </p>
              <p className="body">
                Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
                Kontaktformular werden die von Ihnen mitgeteilten Daten bei uns
                gespeichert, um Ihre<br></br>Fragen zu beantworten. Die in
                diesem Zusammenhang anfallenden Daten löschen wir, nachdem die
                Speicherung nicht mehr erforderlich ist,<br></br>oder schränken
                die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
                bestehen.
              </p>
              <p className="body">
                Falls wir für einzelne Funktionen unseres Angebots auf
                beauftragte Dienstleister zurückgreifen oder Ihre Daten für
                werbliche Zwecke nutzen möchten,<br></br>werden wir Sie unten
                stehend im Detail über die jeweiligen Vorgänge informieren.
                Dabei nennen wir auch die festgelegten Kriterien der
                Speicherdauer.
              </p>
              <p className="body">
                <strong>
                  3. Ihre Rechte<br></br>
                </strong>
                Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
                betreffenden personenbezogenen Daten:
              </p>
              <ul role="list">
                <li>Recht auf Auskunft</li>
                <li>Recht auf Berichtigung oder Löschung,</li>
                <li>Recht auf Einschränkung der Verarbeitung,</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>Recht auf Datenübertragbarkeit.</li>
              </ul>
              <p className="body">
                Sie haben zudem das Recht, sich bei einer
                Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten<br></br> durch uns zu beschweren.
              </p>
              <p className="body">
                <strong>4.</strong>{" "}
                <strong>
                  Erhebung personenbezogener Daten bei Besuch unserer Website
                  <br></br>
                </strong>
                Bei der bloß informatorischen Nutzung der Website, also wenn Sie
                sich nicht registrieren oder uns anderweitig Informationen
                übermitteln,<br></br>erheben wir nur die personenbezogenen
                Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie
                unsere Website betrachten möchten,<br></br>erheben wir die
                folgenden Daten, die für uns technisch erforderlich sind, um
                Ihnen unsere Website anzuzeigen und die Stabilität und
                Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1
                S. 1 lit. f DS-GVO):
              </p>
              <ul role="list">
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils übertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfläche</li>
                <li>Sprache und Version der Browsersoftware.</li>
              </ul>
              <p className="body">
                <strong>
                  5. Sicherungsmaßnahmen zum Schutz der bei uns gespeicherten
                  Daten<br></br>
                </strong>
                Personenbezogene Daten, die Sie uns über unsere Website und im
                E-Mail-Verkehr anvertrauen, sind sicher. Wir verpflichten uns,
                Ihre Privatsphäre zu schützen und Ihre personenbezogenen Daten
                vertraulich zu behandeln.
              </p>
              <p className="body">
                Um einen Verlust oder Missbrauch der bei uns gespeicherten Daten
                zu vermeiden, treffen wir umfangreiche technische und<br></br>
                betriebliche Sicherheitsvorkehrungen, die regelmäßig überprüft
                und dem technologischen Fortschritt angepasst werden.<br></br>
                Wir weisen Sie jedoch darauf hin, dass es aufgrund der Struktur
                des Internets möglich ist, dass die Regeln des Datenschutzes und
                <br></br>die o. g. Sicherungsmaßnahmen von anderen, nicht im
                Verantwortungsbereich von uns liegenden Personen oder
                Institutionen<br></br>nicht beachtet werden. Insbesondere können
                unverschlüsselt preisgegebene Daten – auch wenn dies per E-Mail
                erfolgt – von Dritten<br></br>mitgelesen werden. Wir haben
                technisch hierauf keinen Einfluss. Es liegt im
                Verantwortungsbereich des Nutzers, die von ihm zur<br></br>
                Verfügung gestellten Daten durch Verschlüsselung oder in
                sonstiger Weise gegen Missbrauch zu schützen.
              </p>
              <p className="body">
                <strong>
                  6. Hyperlinks zu fremden Websites<br></br>
                </strong>
                Auf unserer Website können sich sog. Hyperlinks zu Websites
                anderer Anbieter befinden. Bei Aktivierung dieser Hyperlinks
                werden sie von unserer Website direkt auf die Website der
                anderen Anbieter weitergeleitet. Sie erkennen dies u. a. am
                Wechsel der URL. Wir können keine Verantwortung für den
                vertraulichen Umgang Ihrer Daten auf diesen Websites Dritter
                übernehmen, da wir keinen Einfluss darauf haben, dass diese
                Unternehmen die Datenschutzbestimmungen einhalten. Über den
                Umgang mit Ihren personenbezogenen Daten durch diese Unternehmen
                informieren Sie sich bitte auf diesen Websites direkt.
              </p>
              <p className="body">
                <strong>
                  7. Auskunft zu Ihren bei uns gespeicherten personenbezogenen
                  Daten/Löschung<br></br>
                </strong>
                Ihre personenbezogenen Angaben, die Sie im Rahmen der
                Kontaktaufnahme bei uns gemacht haben, speichern wir nur
                solange, wie dies nach dem Gesetz zulässig ist. Gerne teilen wir
                Ihnen auf Ihre Aufforderung hin mit, welche Daten wir zu Ihrer
                Person gespeichert haben. Sollte trotz unseres Bemühens um
                richtige und aktuelle Daten falsche Daten bei uns gespeichert
                sein, so berichtigen wir diese unverzüglich.
              </p>
              <p className="body">
                Sollten Sie die Löschung der gespeicherten Daten wünschen,
                erfolgt diese Löschung &shy;auf Ihre Anforderung hin ebenfalls
                unverzüglich. Ist eine Löschung aus rechtlichen Gründen nicht
                möglich, tritt an ihre Stelle eine Sperrung der jeweiligen
                Daten. Bitte beachten Sie jedoch, dass wir Ihnen bei Löschung
                Ihrer Daten die hier beschriebenen Services nicht mehr anbieten
                können. Bitte wenden Sie sich in diesen Fällen an:
              </p>
              <p className="body"> </p>
              <p className="body">
                Pancon GmbH Gesellschaft für elektromechanische Bauelemente
              </p>
              <p className="body">
                Kaiser-Friedrich-Promenade 4
              </p>
              <p className="body">
                61348 Bad Homburg v. d. Höhe
              </p>
              <p className="body">
                <strong>
                  8. Datenschutzerklärung für die Nutzung von Google Analytics<br></br>
                </strong>
                Diese Website nutzt Funktionen des  Webanalysedienstes Google Analytics. Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics verwendet sog. „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
              </p>
              <p className="body">
                Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
              </p>
              <p className="body">
                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:<a className="Link" target="_blank" href="http://tools.google.com/dlpage/gaoptout?hl=de"> http://tools.google.com/dlpage/gaoptout?hl=de</a>
              </p>
              <h2>&zwj;</h2>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default DatenschutzerklaerungEN;
