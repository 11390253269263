import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import HomeEN from "./components/Pages/HomeEN";
import AboutUs from "./components/Pages/AboutUs";
import Impressum from "./components/Pages/Impressum";
import Datenschutzerklaerung from "./components/Pages/Datenschutzerklaerung";
import Produkte from "./components/Pages/Produkte";
import Produkt from "./components/Pages/Produkt";
import ZubehoerTools from "./components/Pages/ZubehoerTools";
import ZubehoerToolsEN from "./components/Pages/ZubehoerToolsEN";
import Tool from "./components/Pages/Tool";
import Zubehoer from "./components/Pages/Zubehoer";
import Distribution from "./components/Pages/Distribution";
import Kontakt from "./components/Pages/Kontakt";
import AutomotiveSolutions from "./components/Pages/AutomotiveSolutions";
import AutomotiveSolutionsEN from "./components/Pages/AutomotiveSolutionsEN";
import Environment from "./components/Pages/Environment";
import News_Overview from "./components/Pages/News_Overview";
import News_1 from "./components/Pages/News_1";
import Newsletter1_DE from "./components/Pages/Newsletter1_DE";
import Newsletter2_DE from "./components/Pages/Newsletter2_DE";
import References from "./components/Pages/References";
import AboutUsEN from "./components/Pages/AboutUsEN";
import DatenschutzerklaerungEN from "./components/Pages/DatenschutzerklaerungEN";
import DistributionEN from "./components/Pages/DistributionEN";
import EnvironmentEN from "./components/Pages/EnvironmentEN";
import ImpressumEN from "./components/Pages/ImpressumEN";
import KontaktEN from "./components/Pages/KontaktEN";
import News_OverviewEN from "./components/Pages/News_OverviewEN";
import Newsletter1_EN from "./components/Pages/Newsletter1_EN";
import Newsletter2_EN from "./components/Pages/Newsletter2_EN";
import ReferencesEN from "./components/Pages/ReferencesEN";
import ToolEN from "./components/Pages/ToolEN";
import ZubehoerEN from "./components/Pages/ZubehoerEN";
import ProdukteEN from "./components/Pages/ProdukteEN";
import ProduktEN from "./components/Pages/ProduktEN";
import Produktuebersicht from "./components/Pages/Produktuebersicht";
import ProduktuebersichtEN from "./components/Pages/ProduktuebersichtEN";
import LUMEX from "./components/Pages/LUMEX";
import PCN from "./components/Pages/PCN";
import PCN_EN from "./components/Pages/PCN_EN";
import NotFound from "./components/Pages/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Zubehör",
    };
    this.handler = this.handler.bind(this)
  }

  handler(newActive) {
    this.setState({
      active: newActive
    })
  }

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/en/lumex" element={<LUMEX />} />
          <Route path="/en/home" element={<HomeEN />} />
          <Route path="/de/distribution" element={<Distribution />} />
          <Route path="/en/distribution" element={<DistributionEN />} />
          <Route path="/de/automotive-solutions" element={<AutomotiveSolutions />} />
          <Route path="/en/automotive-solutions" element={<AutomotiveSolutionsEN />} />
          <Route path="/de/news" element={<News_Overview />} />
          <Route path="/en/news" element={<News_OverviewEN />} />
          <Route path="/de/news/verfugbarkeitsengpass-die-zeit-ist-reif-fur-pancon" element={<News_1 />} />
          <Route path="/de/news/newsletter-02-22" element={<Newsletter1_DE />} />
          <Route path="/en/news/newsletter-02-22" element={<Newsletter1_EN />} />
          <Route path="/de/news/newsletter-04-23" element={<Newsletter2_DE />} />
          <Route path="/en/news/newsletter-04-23" element={<Newsletter2_EN />} />
          <Route path="/de/umwelt" element={<Environment />} />
          <Route path="/de/pcn" element={<PCN />} />
          <Route path="/en/pcn" element={<PCN_EN />} />
          <Route path="/en/environment" element={<EnvironmentEN />} />
          <Route path="/de/kontakt" element={<Kontakt />} />
          <Route path="/en/contact" element={<KontaktEN />} />
          <Route path="/de/referenzen" element={<References />} />
          <Route path="/en/references" element={<ReferencesEN />} />
          <Route path="/de/ueber-uns" element={<AboutUs />} />
          <Route path="/en/about-us" element={<AboutUsEN />} />
          <Route path="/de/impressum" element={<Impressum />} />
          <Route path="/en/imprint" element={<ImpressumEN />} />
          <Route path="/de/datenschutzerklaerung" element={<Datenschutzerklaerung />} />
          <Route path="/en/privacy-policy" element={<DatenschutzerklaerungEN />} />
          <Route path="/de/produktuebersicht" element={<Produktuebersicht />} />
          <Route path="/en/product-overview" element={<ProduktuebersichtEN />} />
          <Route path="/de/produkte" element={<Produkte />} />
          <Route path="/en/products" element={<ProdukteEN />} />
          <Route path="/de/zubehoer-und-tools" element={<ZubehoerTools active={this.state.active} />} />
          <Route path="/en/accessories-and-tools" element={<ZubehoerToolsEN active={this.state.active} />} />
          <Route path="/de/produkte/:id" element={<Produkt />} />
          <Route path="/en/products/:id" element={<ProduktEN />} />
          <Route path="/de/tools/:id" element={<Tool handler={this.handler} />} />
          <Route path="/en/tools/:id" element={<ToolEN handler={this.handler} />} />
          <Route path="/de/zubehoer/:id" element={<Zubehoer handler={this.handler} />} />
          <Route path="/en/accessories/:id" element={<ZubehoerEN handler={this.handler} />} />
        </Routes>
      </div>
    );
  }
}

export default App;
