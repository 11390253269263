import React, { Component } from "react";
import logo from "../../assets/pancon_logo_large.png";
import { Link } from "react-router-dom";

class ZubehoerToolCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      this.props.lang === "de" ?
        <Link className="card-cont fourth" to={"/" + this.props.lang + "/" + this.props.type + "/" + this.props.product.id}>
          <div className="card">
            <div className="card-left part-30 flex col center-v center-h">
              <img src={logo} width="64" height="64"></img>
              <span className="card-title">{this.props.product.fields.Name}</span>
            </div>
          </div>
        </Link> : <Link className="card-cont fourth" to={"/" + this.props.lang + "/" + this.props.type + "/" + this.props.product.id}>
          <div className="card">
            <div className="card-left part-30 flex col center-v center-h">
              <img src={logo} width="64" height="64"></img>
              <span className="card-title">{this.props.product.fields.Name}</span>
            </div>
          </div>
        </Link>
    );
  }
}

export default ZubehoerToolCard;
