import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import DistributorEl from "../elements/DistributorEl";
import "../../stylesheets/Distribution.css";
import MetaTags from "react-meta-tags";

class Distribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Europa",
      active_c: "",
      visible_dists: [],
      dists: [],
      offset: "",
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let offset = "";
    let count = 10;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app3vTiQfUs5SKScQ/tblWMZL0IRJhXNV6u?offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }
    this.setState({
      dists: records,
    });
  };

  changeView = (e, target) => {
    this.setState({
      active: target,
      visible_dists: [],
    });
  };

  changeCountry = (e, target) => {
    let visible_dists = this.state.dists.filter(function (dist) {
      return dist.fields.Land === target;
    });
    this.setState({
      active_c: target,
      visible_dists: visible_dists,
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Distribution</title>
          <meta
            name="description"
            content="Wir arbeiten mit verschiedenen Distributoren auf dem Markt der
elektromechanischen Komponenten zusammen. Wir haben die Lösung, unsere
regionalen, sowie internationalen Partner haben unsere Produkte auf Lager."
          />
          <meta property="og:title" content="Pancon - Distribution" />
          <meta
            property="og:description"
            content="Wir arbeiten mit verschiedenen Distributoren auf dem Markt der
elektromechanischen Komponenten zusammen. Wir haben die Lösung, unsere
regionalen, sowie internationalen Partner haben unsere Produkte auf Lager."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/distribution"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/distribution"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="section first less-bot">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>Distribution</h1>
          </div>
          <div className="flex center-h marg-top">
            <button
              className={
                this.state.active === "Europa"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Europa")}
            >
              Europa
            </button>
            <button
              className={
                this.state.active === "Nordamerika"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Nordamerika")}
            >
              Nordamerika
            </button>
            <button
              className={
                this.state.active === "Asien" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Asien")}
            >
              Asien
            </button>
          </div>
        </div>
        <div className="less-bot section flex center-h">
          <div className="center-mob medium flex">
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Griechenland")}
                className="dist-country"
              >
                Griechenland
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Polen")}
                className="dist-country"
              >
                Polen
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Finnland")}
                className="dist-country"
              >
                Finnland
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Deutschland")}
                className="dist-country"
              >
                Deutschland
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Italien")}
                className="dist-country"
              >
                Italien
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Frankreich")}
                className="dist-country"
              >
                Frankreich
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "UK")}
                className="dist-country"
              >
                UK
              </div>
            ) : null}
            {this.state.active == "Europa" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Spanien")}
                className="dist-country"
              >
                Spanien
              </div>
            ) : null}
            {this.state.active == "Nordamerika" ? (
              <div
                onClick={(event) => this.changeCountry(event, "USA")}
                className="dist-country"
              >
                USA
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "China")}
                className="dist-country"
              >
                China
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Malaysia")}
                className="dist-country"
              >
                Malaysia
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Singapor")}
                className="dist-country"
              >
                Singapor
              </div>
            ) : null}
            {this.state.active == "Asien" ? (
              <div
                onClick={(event) => this.changeCountry(event, "Taiwan")}
                className="dist-country"
              >
                Taiwan
              </div>
            ) : null}
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            {this.state.visible_dists.map((dist, i) => (
              <DistributorEl key={i} dist={dist} />
            ))}
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Distribution;
