import React, { Component } from "react";
import NavEN from "../Symbols/NavEN";
import FooterEN from "../Symbols/FooterEN";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import Logo from "../../assets/pancon_logo_large.png";
import Newsletter from "../../assets/newsletter_1.pdf";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

class Newsletter1_EN extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      file: Newsletter,
      numPages: 0,
      pageNumber: 1,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  backPage = () => {
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber - 1 < 1) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber - 1;
    }

    this.setState({
      pageNumber: nextPageNumber,
    });
  };

  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <div className="content">
        <MetaTags>
          <title>News - Newsletter 02/22</title>
          <meta
            name="description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <meta property="og:title" content="News - Newsletter 02/22" />
          <meta
            property="og:description"
            content="Pancon, based in Germany, is producing and distributing electromechanical components since 1968."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/newsletter-02-22"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/newsletter-02-22"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavEN />
        <div className="section flex first intro news center-h less-bot">
          <div className="medium flex">
            <div className="heading1-cont alt flex center-v col center-h">
              <h1>Newsletter 02/22</h1>
              <p className="body desc">2022</p>
              <img src={Logo}></img>
              <div className="bottom-divider"></div>
              <Document
                file={this.state.file}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div id="navigation_controls" className="flex col center-h">
                {this.state.file ? (
                  <p className="body center">
                    Page {pageNumber} of {numPages}
                  </p>
                ) : null}
                <div className="flex center-h">
                  <button onClick={this.backPage} className="default m-right-1">
                    Previous Page
                  </button>
                  <button onClick={this.nextPage} className="default">
                    Next Page
                  </button>
                </div>
              </div>
              <div className="flex center-h center-v">
                <a href={Newsletter} target={"_blank"} className="default env">
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterEN />
      </div>
    );
  }
}

export default Newsletter1_EN;
