import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import ProductCard from "../elements/ProductCard";
import "../../stylesheets/Produkte.css";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Spinner } from "loading-animations-react";
import { FaFilter } from "react-icons/fa";

class Produkte extends Component {
  constructor(props) {
    super(props);
    this.searchInputMain = React.createRef();
    this.filterCont = React.createRef();
    this.MC = React.createRef();
    this.CC = React.createRef();
    this.BC = React.createRef();
    this.HC = React.createRef();
    this.LC = React.createRef();
    this.state = {
      pagination_page: 0,
      paginated_products: [],
      filtered_products: [],
      products: [],
      offset: "",
      filter_active_toggle: false,
      active_filter: {
        search_query: "",
        Kategorie: [],
        Produktreihe: "",
        Pitch: [],
        Gender_DE: "",
        Connectivity_DE: [],
        Orientation: "",
        Number_of_Rows: [],
        Elevated_DE: "",
        Locating_Peg_DE: "",
        Polarized_DE: "",
        Profile: [],
      },
    };
    this.filterToggle = this.filterToggle.bind(this);
  }

  componentDidMount() {
    this.fetchFirstBatch();
    this.fetchData();
  }

  fetchFirstBatch = async () => {
    let offset = "";
    let records = [];

    const response = await fetch(
      `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/Produkte?view=Published&offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
    );
    const data = await response.json();
    records = records.concat(data.records);
    if (data.offset) {
      offset = data.offset;
    }

    this.setState({
      offset: offset,
      products: records,
      filtered_products: records,
      paginated_products: records.slice(this.state.pagination_page * 20, 20),
    });
  };

  fetchData = async () => {
    let offset = this.state.offset;
    let count = 20;
    let records = [];
    for (let i = 0; i < count; i++) {
      const response = await fetch(
        `https://api.airtable.com/v0/app1t0Kdg1xsbWoWn/Produkte?view=Published&offset=${offset}`, { headers: { 'Authorization': 'Bearer patSxqK40aDpd0iY6.beedbae8aef0d8ef37eaa0268af4654eb5113643efb69777ef67367c2031ee09' } }
      );
      const data = await response.json();
      records = records.concat(data.records);
      if (data.offset) {
        offset = data.offset;
      } else {
        break;
      }
    }
    this.setState({
      products: records,
      filtered_products: records,
    }, this.checkParams);
  };

  checkParams = () => {
    let series = ""
    let params = window.location.search;
    params = params.replace("?", "&").split("&");
    params.forEach((param) => {
      if (param.includes("search=")) {
        let search = param.replace("search=", "");
        search = search.toLowerCase();
        if (search.length > 2) {
          let active_filter = this.state.active_filter;
          active_filter["search_query"] = search;
          this.searchInputMain.current.value = search;
          this.setState({
            active_filter: active_filter,
            filter_active_toggle: true,
          });
          this.filterProducts();
        }
      } else if (param.includes("series=")) {
        series = param.replace("series=", "");
        if (series.length > 2) {
          let active_filter = this.state.active_filter;
          active_filter["Produktreihe"] = series;
          this.setState({
            active_filter: active_filter,
            filter_active_toggle: true,
          });
          switch (series) {
            case "MAS-CON":
              this.MC.current.classList.add("active")
              break;
            case "CRIMP-CON":
              this.CC.current.classList.add("active")
              break;
            case "HI-CON":
              this.HC.current.classList.add("active")
              break;
            case "LAT-CON":
              this.LC.current.classList.add("active")
              break;
            default:
              this.BC.current.classList.add("active")
          }
          this.filterProducts();
        }
      }
    });
  }

  previousPage = (e) => {
    let currentPage = this.state.pagination_page;
    if (currentPage > 0) {
      currentPage = currentPage - 1;
    }
    this.setState({
      paginated_products: this.state.filtered_products.slice(
        currentPage * 20,
        currentPage * 20 + 20
      ),
      pagination_page: currentPage,
    });
  };

  nextPage = (e) => {
    let currentPage = this.state.pagination_page;
    if (
      currentPage <=
      Math.ceil(this.state.filtered_products.length / 20) - 2
    ) {
      currentPage = currentPage + 1;
    }
    this.setState({
      paginated_products: this.state.filtered_products.slice(
        currentPage * 20,
        currentPage * 20 + 20
      ),
      pagination_page: currentPage,
    });
  };

  filterVisibility = (filtered_products_cache, active_filter) => {
    var filterList = [
      "Kategorie",
      "Produktreihe",
      "Pitch",
      "Gender_DE",
      "Connectivity_DE",
      "Orientation",
      "Number_of_Rows",
      "Elevated_DE",
      "Locating_Peg_DE",
      "Polarized_DE",
      "Profile",
    ];
    var checkboxes = this.filterCont.current.querySelectorAll(
      ".checkbox-cont .checkbox-label"
    );
    var buttons = this.filterCont.current.querySelectorAll(
      "button.filter-button"
    );
    checkboxes.forEach((checkbox) => {
      var label = checkbox.innerHTML;
      var field = checkbox.parentElement.parentElement.parentElement.id;
      var visible = false;
      filtered_products_cache.forEach((filtered_product) => {
        if (filtered_product.fields[field] == label) {
          visible = true;
          let index = filterList.indexOf(field);
          if (index !== -1) {
            filterList.splice(index, 1);
          }
          return;
        }
      });
      if (!visible) {
        if (this.state.active_filter[field].length == 0) {
          checkbox.parentElement.classList.add("hidden");
        }
      }
    });
    buttons.forEach((button) => {
      var label = button.innerHTML;
      if (label === "BOARD-CON") {
        label = "Board-Con"
      }
      else if (label === "Federleiste") {
        label = "Weiblich"
      }
      else if (label === "Messerleiste") {
        label = "Männlich"
      }
      var field = button.parentElement.parentElement.id;
      var visible = false;
      filtered_products_cache.forEach((filtered_product) => {
        if (
          filtered_product.fields[field] == label ||
          (label === "Nein" && filtered_product.fields[field] === "k.A.")
        ) {
          visible = true;
          let index = filterList.indexOf(field);
          if (index !== -1) {
            filterList.splice(index, 1);
          }
          return;
        }
      });
      if (!visible) {
        if (this.state.active_filter[field].length == 0) {
          button.classList.add("hidden");
        }
      }
    });
    if (filterList) {
      filterList.forEach((filter) => {
        document.getElementById(filter).classList.add("hidden");
      });
    }
  };

  filterProducts = () => {
    let active_filter = Object.entries(this.state.active_filter);
    let filtered_products_cache = this.state.products.filter(function (
      outerElem
    ) {
      var valid = true;
      active_filter.forEach((innerElem) => {
        let field = innerElem[0];
        let target = field === "Number_of_Rows" && outerElem.fields[field] ? outerElem.fields[field].toString() : outerElem.fields[field];
        if (innerElem[1].length > 0) {
          if (Array.isArray(innerElem[1])) {
            if (field === "Kategorie") {
              if (!innerElem[1].some((v) => target.includes(v))) {
                valid = false;
                return;
              }
            } else {
              if (!innerElem[1].includes(target)) {
                valid = false;
                return;
              }
            }
          } else if (field === "search_query") {
            let search_str = innerElem[1];
            let name = outerElem.fields.Name_Neu.toLowerCase();

            const regex = /[xX#]{1}/g;
            let re = name.replace(regex, '[a-zA-Z0-9#]?');
            re = new RegExp(re);

/*             if (re.test(search_str)) {
              console.log(re)
              console.log(name)
              console.log(search_str)
            } */

            let desc_de = outerElem.fields.Beschreibung_DE_Neu.toLowerCase();
            let desc_en = outerElem.fields.Beschreibung_EN_Neu.toLowerCase();

            if (
              !(
                re.test(search_str) ||
                name.includes(search_str) ||
                desc_de.includes(search_str) ||
                desc_en.includes(search_str)
              )
            ) {
              valid = false;
              return;
            }
          } else if (
            typeof innerElem[1] === "string" ||
            innerElem[1] instanceof String
          ) {
            if (
              innerElem[1] !== target &&
              !(innerElem[1] === "Nein" && target === "k.A.")
            ) {
              valid = false;
              return;
            }
          }
        }
      });
      return valid;
    });

    this.setState({
      filtered_products: filtered_products_cache,
      paginated_products: filtered_products_cache.slice(0, 20),
      pagination_page: 0,
    });

    this.filterVisibility(filtered_products_cache, active_filter);
  };

  preFilter = (e, field, value) => {
    this.resetVisibility();
    if ("checked" in e.target && field !== "search_query") {
      let checked = e.target.checked;
      let active_filter = this.state.active_filter;
      let values = active_filter[field];

      if (checked && !values.includes(value)) {
        values.push(value);
      } else if (!checked) {
        let index = values.indexOf(value);
        if (index > -1) {
          values.splice(index, 1);
        }
      }
      active_filter[field] = values;
      this.setState({
        active_filter: active_filter,
        filter_active_toggle: true,
      });
      this.filterProducts();
    } else if (field === "search_query") {
      if (e.target.value.length > 2) {
        let active_filter = this.state.active_filter;
        active_filter[field] = e.target.value.toLowerCase();
        this.setState({
          active_filter: active_filter,
          filter_active_toggle: true,
        });
        this.filterProducts();
      } else {
        let active_filter = this.state.active_filter;
        active_filter[field] = "";
        this.setState({
          active_filter: active_filter,
          filter_active_toggle: false,
        });
        this.filterProducts();
      }
    } else {
      if (e.target.classList.contains("active")) {
        let neighbours = e.target.parentElement.querySelectorAll(
          "button.filter-button"
        );
        neighbours.forEach((neighbour) => {
          neighbour.classList.remove("active");
        });
        let active_filter = this.state.active_filter;
        active_filter[field] = "";
        this.setState({
          active_filter: active_filter,
          filter_active_toggle: true,
        });
        this.filterProducts();
      } else {
        let neighbours = e.target.parentElement.querySelectorAll(
          "button.filter-button"
        );
        neighbours.forEach((neighbour) => {
          neighbour.classList.remove("active");
        });
        e.target.classList.add("active");
        let active_filter = this.state.active_filter;
        active_filter[field] = value;
        this.setState({
          active_filter: active_filter,
          filter_active_toggle: true,
        });
        this.filterProducts();
      }
    }
  };

  resetVisibility = () => {
    let checkboxes = this.filterCont.current.querySelectorAll("input.checkbox");
    let buttons = this.filterCont.current.querySelectorAll(
      "button.filter-button"
    );

    checkboxes.forEach((checkbox) => {
      checkbox.parentElement.classList.remove("hidden");
    });

    buttons.forEach((button) => {
      button.classList.remove("hidden");
    });

    var filterList = [
      "Kategorie",
      "Produktreihe",
      "Pitch",
      "Gender_DE",
      "Connectivity_DE",
      "Orientation",
      "Number_of_Rows",
      "Elevated_DE",
      "Locating_Peg_DE",
      "Polarized_DE",
      "Profile",
    ];

    if (filterList) {
      filterList.forEach((filter) => {
        document.getElementById(filter).classList.remove("hidden");
      });
    }
  }

  resetFilter = (e) => {
    this.searchInputMain.current.value = "";

    let checkboxes = this.filterCont.current.querySelectorAll("input.checkbox");
    let buttons = this.filterCont.current.querySelectorAll(
      "button.filter-button"
    );

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    buttons.forEach((button) => {
      button.classList.remove("active");
    });

    this.resetVisibility();

    this.setState({
      filtered_products: this.state.products,
      paginated_products: this.state.products.slice(0, 20),
      pagination_page: 0,
      filter_active_toggle: false,
      active_filter: {
        search_query: "",
        Kategorie: [],
        Produktreihe: "",
        Pitch: [],
        Gender_DE: "",
        Connectivity_DE: [],
        Orientation: "",
        Number_of_Rows: [],
        Elevated_DE: "",
        Locating_Peg_DE: "",
        Polarized_DE: "",
        Profile: [],
      },
    });
  };

  filterToggle(e) {
    if (this.filterCont.current.classList.contains("open")) {
      this.filterCont.current.classList.remove("open");
    } else {
      this.filterCont.current.classList.add("open");
    }
    e.preventDefault();
  }

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Produkte</title>
          <meta
            name="description"
            content="Pancon bietet verschiedene Arten von Steckverbindern an. Unsere
Produkte sind die optimale Lösung für Ihre Anforderungen im Bereich der
Konnektoren."
          />
          <meta property="og:title" content="Pancon - Produkte" />
          <meta
            property="og:description"
            content="Pancon bietet verschiedene Arten von Steckverbindern an. Unsere
Produkte sind die optimale Lösung für Ihre Anforderungen im Bereich der
Konnektoren."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/produkte"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/products"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="section first">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1>Produkte</h1>
          </div>
          <div className="flex spb v-end hfg">
            <div>
              {this.state.filter_active_toggle ? (
                <p>Filter sind aktiv</p>
              ) : (
                <p>Keine aktiven Filter</p>
              )}
              <button className="default" onClick={this.resetFilter}>
                Filter zurücksetzen
              </button>
            </div>
            {this.state.products.length <= 100 ? (
              <div className="flex center-h center-v spinner">
                <Spinner
                  id="spinner"
                  color1="#d6112b"
                  color2="#d6112b"
                  textColor="black"
                />
              </div>
            ) : (
              <p className="body count">
                {this.state.filtered_products.length} Datensätze
              </p>
            )}
            <div>
              <Link to="/de/zubehoer-und-tools">
                <button className="default">Zubehör {"&"} Tools</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="section flex products">
          <a
            href="#"
            onClick={this.filterToggle}
            id="toggle-filter"
            className="flex center-h center-v"
          >
            <FaFilter size={24} />
          </a>
          <div ref={this.filterCont} id="filter-cont-fifth" className="fifth">
            <h3 className="filter-header">Suchen</h3>
            <input
              ref={this.searchInputMain}
              type="text"
              placeholder="Suchen..."
              onChange={(event) =>
                this.preFilter(event, "search_query", this.value)
              }
              className="search-input-filter"
            />
            <div className="bottom-divider"></div>
            <div id="Kategorie">
              <h3 className="filter-header">Kategorie</h3>
              <div className="filter-category-cont flex">
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Kategorie", "Wire 2 Wire")
                    }
                  ></input>
                  <span className="checkbox-label">Wire 2 Wire</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Kategorie", "Board 2 Board")
                    }
                  ></input>
                  <span className="checkbox-label">Board 2 Board</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Kategorie", "Wire 2 Board")
                    }
                  ></input>
                  <span className="checkbox-label">Wire 2 Board</span>
                </label>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Produktreihe">
              <h3 className="filter-header">Produktreihe</h3>
              <div>
                <button
                  ref={this.MC}
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Produktreihe", "MAS-CON")
                  }
                >
                  MAS-CON
                </button>
                <button
                  ref={this.CC}
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Produktreihe", "CRIMP-CON")
                  }
                >
                  CRIMP-CON
                </button>
                <button
                  ref={this.BC}
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Produktreihe", "Board-Con")
                  }
                >
                  BOARD-CON
                </button>
                <button
                  ref={this.HC}
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Produktreihe", "HI-CON")
                  }
                >
                  HI-CON
                </button>
                <button
                  ref={this.LC}
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Produktreihe", "LAT-CON")
                  }
                >
                  LAT-CON
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Pitch">
              <h3 className="filter-header">Raster</h3>
              <div className="filter-category-cont flex">
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "1.00")}
                  ></input>
                  <span className="checkbox-label">1.00</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "3.00")}
                  ></input>
                  <span className="checkbox-label">3.00</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "1.27")}
                  ></input>
                  <span className="checkbox-label">1.27</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "3.96")}
                  ></input>
                  <span className="checkbox-label">3.96</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "2.00")}
                  ></input>
                  <span className="checkbox-label">2.00</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "4.20")}
                  ></input>
                  <span className="checkbox-label">4.20</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "2.50")}
                  ></input>
                  <span className="checkbox-label">2.50</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "5.08")}
                  ></input>
                  <span className="checkbox-label">5.08</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) => this.preFilter(event, "Pitch", "2.54")}
                  ></input>
                  <span className="checkbox-label">2.54</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Pitch", "0.80 x 1.20")
                    }
                  ></input>
                  <span className="checkbox-label">0.80 x 1.20</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Pitch", "3.50 x 6.00")
                    }
                  ></input>
                  <span className="checkbox-label">3.50 x 6.00</span>
                </label>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Gender_DE">
              <h3 className="filter-header">Geschlecht</h3>
              <div>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Gender_DE", "Weiblich")
                  }
                >
                  Federleiste
                </button>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Gender_DE", "Männlich")
                  }
                >
                  Messerleiste
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Connectivity_DE">
              <h3 className="filter-header">Konnektivität</h3>
              <div className="filter-category-cont flex">
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "Kante")
                    }
                  ></input>
                  <span className="checkbox-label">Kante</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "Durchstecklöten")
                    }
                  ></input>
                  <span className="checkbox-label">Durchstecklöten</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "SMT")
                    }
                  ></input>
                  <span className="checkbox-label">SMT</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "Crimp")
                    }
                  ></input>
                  <span className="checkbox-label">Crimp</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "Lötöse")
                    }
                  ></input>
                  <span className="checkbox-label">Lötöse</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Connectivity_DE", "IDC")
                    }
                  ></input>
                  <span className="checkbox-label">IDC</span>
                </label>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Orientation">
              <h3 className="filter-header">Orientierung</h3>
              <div>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Orientation", "0°")
                  }
                >
                  0°
                </button>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Orientation", "90°")
                  }
                >
                  90°
                </button>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Orientation", "180°")
                  }
                >
                  180°
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Number_of_Rows">
              <h3 className="filter-header">Reihenanzahl</h3>
              <div className="filter-category-cont flex">
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "1")
                    }
                  ></input>
                  <span className="checkbox-label">1</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "4")
                    }
                  ></input>
                  <span className="checkbox-label">4</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "2")
                    }
                  ></input>
                  <span className="checkbox-label">2</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "5")
                    }
                  ></input>
                  <span className="checkbox-label">5</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "3")
                    }
                  ></input>
                  <span className="checkbox-label">3</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Number_of_Rows", "6")
                    }
                  ></input>
                  <span className="checkbox-label">6</span>
                </label>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Elevated_DE">
              <h3 className="filter-header">Erhöht</h3>
              <div>
                <button
                  className="filter-button"
                  onClick={(event) => this.preFilter(event, "Elevated_DE", "Ja")}
                >
                  Ja
                </button>
                <button
                  className="filter-button"
                  onClick={(event) => this.preFilter(event, "Elevated_DE", "Nein")}
                >
                  Nein
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Locating_Peg_DE">
              <h3 className="filter-header">Positionierungsstift</h3>
              <div>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Locating_Peg_DE", "Ja")
                  }
                >
                  Ja
                </button>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Locating_Peg_DE", "Nein")
                  }
                >
                  Nein
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Polarized_DE">
              <h3 className="filter-header">Polarisiert</h3>
              <div>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Polarized_DE", "Ja")
                  }
                >
                  Ja
                </button>
                <button
                  className="filter-button"
                  onClick={(event) =>
                    this.preFilter(event, "Polarized_DE", "Nein")
                  }
                >
                  Nein
                </button>
              </div>
              <div className="bottom-divider"></div>
            </div>
            <div id="Profile">
              <h3 className="filter-header">Profil</h3>
              <div className="filter-category-cont flex">
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "2.5 - 3.2")
                    }
                  ></input>
                  <span className="checkbox-label">2.5 - 3.2</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "1.0 - 1.8")
                    }
                  ></input>
                  <span className="checkbox-label">1.0 - 1.8</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "3.3 - 4.50")
                    }
                  ></input>
                  <span className="checkbox-label">3.3 - 4.50</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "2.0 - 2.4")
                    }
                  ></input>
                  <span className="checkbox-label">2.0 - 2.4</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "4.57 - 6.8")
                    }
                  ></input>
                  <span className="checkbox-label">4.57 - 6.8</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "11.4 +")
                    }
                  ></input>
                  <span className="checkbox-label">11.4 +</span>
                </label>
                <label className="w-checkbox checkbox-cont">
                  <input
                    className="checkbox"
                    type="checkbox"
                    onChange={(event) =>
                      this.preFilter(event, "Profile", "7.0 - 11.3")
                    }
                  ></input>
                  <span className="checkbox-label">7.0 - 11.3</span>
                </label>
              </div>
              <div className="bottom-divider"></div>
            </div>
          </div>
          <div className="four-fifths">
            <div id="products">
              {this.state.paginated_products.map((product, i) => (
                <ProductCard key={i} lang="de/produkte" product={product} />
              ))}
              {this.state.products.length >= 100 ? (
                <div
                  id="pagination-control"
                  className="flex row center-h full-width"
                >
                  <div>
                    <button onClick={this.previousPage} className="default">
                      {"<"}
                    </button>
                  </div>
                  <div>
                    <p className="body">
                      Seite {this.state.pagination_page + 1} von{" "}
                      {Math.ceil(this.state.filtered_products.length / 20)}
                    </p>
                  </div>
                  <div>
                    <button onClick={this.nextPage} className="default">
                      {">"}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default Produkte;
