import React, { Component } from "react";

class NewsCard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      this.props.lang === "de" && this.props.news.fields.Link !== undefined ?
        <div className="card news flex center-v center-h">
          {/*           <div className="part-45 news-part flex">
            <img src={logo}></img>
            <div>
              <span>PANCON</span>
              <br></br>
              <span>CONNECTORS</span>
            </div>
          </div> */}
          <h4 className="card-title news">{this.props.news.fields.Titel}</h4>
          <img className="news-pic" src={this.props.news.fields.Bild[0].url}></img>
          <span className="news-desc">{this.props.news.fields.Beschreibung_DE}</span>
          <a href={this.props.news.fields.Link} target="_blank" className="marg-top-10 default">Mehr erfahren</a>
        </div>

        : this.props.lang === "de" && this.props.news.fields.Link === undefined ?
          <div className="card news flex center-v center-h">
            {/*           <div className="part-45 news-part flex">
          <img src={logo}></img>
          <div>
            <span>PANCON</span>
            <br></br>
            <span>CONNECTORS</span>
          </div>
        </div> */}
            <h4 className="card-title news">{this.props.news.fields.Titel}</h4>
            <img className="news-pic" src={this.props.news.fields.Bild[0].url}></img>
            <span className="news-desc">{this.props.news.fields.Beschreibung_DE}</span>
          </div>
          : this.props.lang === "en" && this.props.news.fields.Link !== undefined ?
            <div className="card news flex center-v center-h">
              {/*         <div className="part-45 news-part flex">
        <img src={logo}></img>
        <div>
          <span>PANCON</span>
          <br></br>
          <span>CONNECTORS</span>
        </div>
      </div> */}
              <h4 className="card-title news">{this.props.news.fields.Titel_EN}</h4>
              <img className="news-pic" src={this.props.news.fields.Bild[0].url}></img>
              <span className="news-desc">{this.props.news.fields.Beschreibung_EN}</span>
              <a href={this.props.news.fields.Link} target="_blank" className="marg-top-10 default">Learn more</a>
            </div>
            :
            <div className="card news flex center-v center-h">
              {/*         <div className="part-45 news-part flex">
          <img src={logo}></img>
          <div>
            <span>PANCON</span>
            <br></br>
            <span>CONNECTORS</span>
          </div>
        </div> */}
              <h4 className="card-title news">{this.props.news.fields.Titel_EN}</h4>
              <img className="news-pic" src={this.props.news.fields.Bild[0].url}></img>
              <span className="news-desc">{this.props.news.fields.Beschreibung_EN}</span>
            </div>
    );
  }
}

export default NewsCard;
